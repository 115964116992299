import axios from 'axios';
import { SWRConfig } from 'swr'
import { Provider } from 'react-redux';
import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { LaravelReactI18nProvider } from 'laravel-react-i18n';
import { ToastContainer } from "react-toastify";
import store from '@/store'
import './sentry'
import './echo'

createInertiaApp({
    progress: {
        color: '#000',
    },
    resolve: (name) => resolvePageComponent(`./pages/${name}.jsx`, import.meta.glob('./pages/**/*.jsx')),
    setup({ el, App, props }) {
        createRoot(el).render(
            <SWRConfig
                value={{
                    fetcher: (resource, init) => axios(resource, init).then(res => res.data)
                }}
            >
                <Provider store={store}>
                    <LaravelReactI18nProvider
                        fallbackLocale={'pt-BR'}
                        files={import.meta.glob('/lang/*.json', { eager: true })}
                    >
                        <ToastContainer />
                        <App {...props} />
                    </LaravelReactI18nProvider>
                </Provider>
            </SWRConfig>
        )
    }
})
